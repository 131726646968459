<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="warehouseinfo" :title="$t('Warehouse Info')">
            <b-row>
              <b-col cols="12">
                <b-form-group :label="`*` + $t('Name') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Manager') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Manager"
                  >
                    <v-select
                      v-if="userOptions"
                      v-model="model.user_id"
                      label="name"
                      :reduce="x => x.id"
                      :options="userOptions"
                      @input="setPhone(model.manager)"
                    >
                      <template v-slot:option="option">
                        <b-media vertical-align="center">
                          <template #aside>
                            <b-avatar
                              size="32"
                              :src="option.avatar"
                              :text="avatarText(option.name)"
                            />
                          </template>
                          <span class="d-block">{{ option.name }}</span>
                          <small class="text-muted">chi nhánh 1</small>
                        </b-media>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Phone') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                  >
                    <b-form-input
                      v-model="model.phone"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Phone')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Province/City') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Province"
                    rules="required"
                  >
                    <v-select
                      v-if="provinceOptions"
                      v-model="model.province"
                      label="name"
                      :reduce="x => x.id"
                      :options="provinceOptions"
                      :state="errors.length > 0 ? false : null"
                      @input="changeDistrict(model.province)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('District') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="District"
                    rules="required"
                  >
                    <v-select
                      v-if="districtOptions"
                      v-model="model.district"
                      label="name"
                      :reduce="x => x.id"
                      :options="districtOptions"
                      :state="errors.length > 0 ? false : null"
                      @input="changeWards(model.district)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Wards') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Wards"
                    rules="required"
                  >
                    <v-select
                      v-if="wardsOptions"
                      v-model="model.wards"
                      label="name"
                      :reduce="x => x.id"
                      :options="wardsOptions"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Address') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.address"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Address')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <!-- <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </Fieldset> -->
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Position')+`:`" label-cols-md="6">
                <b-form-input
                  v-model="model.position"
                  type="number"
                  :placeholder="$t('Position')"
                />
              </b-form-group>
              <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <hr>

              <!-- Submit -->
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  {{ $t('Update') }}
                </b-button>
              </div>
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  // BFormTextarea,
  BFormCheckbox,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      avatarText,
      required,
      model: {
        name: null,
        manager: null,
        province: null,
        district: null,
        address: null,
        phone: null,
        position: null,
        is_active: true,
      },
      userOptions: [
        {
          id: 1,
          name: 'Nguyen Van A',
          phone: '0987654321',
        },
        {
          id: 12,
          name: 'Nguyen Oanh',
          phone: '0987526277',
        },
        {
          id: 13,
          name: 'Tran Dang',
          phone: '0876257189',
        },
      ],
      provinceOptions: [
        {
          id: 1,
          name: 'Hà Nội',
        },
        {
          id: 12,
          name: 'TP. Hồ Chí Minh',
        },
      ],
      districtOptions: [
        {
          id: 1,
          name: 'Quận 1',
        },
        {
          id: 12,
          name: 'Quận Tân Bình',
        },
      ],
      wardsOptions: [
        {
          id: 1,
          name: 'Phường 2',
        },
        {
          id: 12,
          name: 'Phường 3',
        },
      ],
      storage: {},
    }
  },
  async created() {
    await this.getStorage()
    this.loadDetail()
  },
  methods: {
    generateSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    setPhone(id) {
      const val = this.userOptions.find(x => x.id === id)
      this.model.phone = val.phone
    },
    async getStorage() {
      const res = await this.mixGet('/storages', { limit: 1000 })
      if (res.status) {
        if (res.data) {
          this.storage = res.data.items.find(x => x.is_default === 1)
        }
      }
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/storage/${this.storage.id_number}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = (res.data.data)
            this.model.is_active = Boolean(
              res.data.data.is_active === 1,
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              address: this.model.address,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              name: this.model.name,
              phone: this.model.phone,
              address: this.model.address,
              // translation: JSON.stringify(translation),
            }
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/storage/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Update success',
                  },
                })
                this.$router.push('/warehouse-list/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
.box-list {
  margin-top: 2rem;
}
</style>
